import classnames from 'classnames';
import Image, { ImageProps } from 'next/image';
import React, { useEffect, useState } from 'react';
import Container from '../../../Container';
import styles from './index.module.scss';

export type Props = {
  className?: string;
  children?: React.ReactNode;
  aside?: React.ReactNode;
  textColor?: string;
  background?: any;
  backgroundOpacity?: number;
  transparentBg?: boolean;
  color?: 'white' | 'black';
  size?: 'small' | 'medium' | 'large';
  backgroundLoader?: ImageProps['loader'];
} & Record<string, any>;

export default function StandardBanner(props: Props) {
  const {
    className,
    color,
    children,
    aside,
    size,
    textColor,
    background,
    backgroundLoader,
    backgroundOpacity = 1,
    fullWidth = false,
    transparentBg = false,
  } = props;

  const [isAsideVisible, setIsAsideVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => setIsAsideVisible(true), 300);
  }, []);

  return (
    <header
      className={classnames(
        styles.banner,
        !textColor && styles[color],
        styles[size],
        className,
        transparentBg && styles.transparentBg,
      )}
    >
      {background && (
        <div className={styles.background} style={{ opacity: backgroundOpacity }}>
          <Image src={background} layout="fill" loader={backgroundLoader} objectFit="cover" quality={80} priority />
        </div>
      )}
      <Container className={styles.container}>
        <div
          className={classnames(styles.content, fullWidth && styles.fullWidth)}
          style={textColor ? { color: textColor } : undefined}
        >
          {children}
        </div>
        {aside && <div className={classnames(styles.aside, isAsideVisible && styles.isAsideVisible)}>{aside}</div>}
      </Container>
    </header>
  );
}
